import { useEffect, useCallback } from "react";

import { useSelector } from "react-redux";

import { useFlags } from "Services/FeatureFlagService";
import { selectConference } from "Services/state/conferences";
import { setActiveConferenceTask } from "Services/state/conferences/ConferenceStateSlice";
import { useRVDispatch } from "Services/state/Storage";
import { selectTasks } from "Services/state/tasks/Selectors";
import {
    isPendingInboundConferenceTask,
    isConnectedProgressiveDialerTask,
    isAcceptedConferenceTask,
} from "Services/Task.service";

import type { RVTask } from "@regal-voice/shared-types";

export type UseLegacyActiveCallEventsOptions = {
    activeConferenceTask?: RVTask;
};

export const useLegacyActiveCallEvents = ({ activeConferenceTask }: UseLegacyActiveCallEventsOptions = {}) => {
    const dispatch = useRVDispatch();
    const { multipleCalls } = useFlags();
    const { deviceCallSid, conferenceFriendlyId } = useSelector(selectConference);
    const tasks = useSelector(selectTasks);
    const effectKey = Object.values(tasks)
        .map((task) => `${task.sid}-${task.taskStatus}`)
        .join(",");

    const dispatchActiveConferenceTask = useCallback(
        (task?: RVTask) => {
            dispatch(setActiveConferenceTask({ id: task?.sid }));
        },
        [dispatch]
    );

    useEffect(() => {
        const taskList = Object.values(tasks);
        const inboundConferenceTask = taskList.find(isPendingInboundConferenceTask);
        const acceptedConferenceTask = taskList.find(isAcceptedConferenceTask);
        if (multipleCalls) {
            // only if accepted or pending, otherwise will go through step1
            if (!deviceCallSid && !acceptedConferenceTask && inboundConferenceTask?.status === "pending") {
                dispatchActiveConferenceTask(inboundConferenceTask);
            }
        } else {
            // only if pending, otherwise will go through step1
            if (!deviceCallSid && inboundConferenceTask?.status == "pending") {
                dispatchActiveConferenceTask(inboundConferenceTask);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- These are the only changes we care about
    }, [effectKey, activeConferenceTask?.sid, deviceCallSid, conferenceFriendlyId]);

    useEffect(() => {
        const progDialerTask = Object.values(tasks).find(isConnectedProgressiveDialerTask);
        // only if pending, otherwise will go through step1
        if (!activeConferenceTask?.sid && progDialerTask) {
            dispatchActiveConferenceTask(progDialerTask);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effectKey, activeConferenceTask?.sid, deviceCallSid, conferenceFriendlyId]);

    useEffect(() => {
        // troubling, we should handle not having an active conference task
        if (activeConferenceTask && !tasks[activeConferenceTask.sid]) {
            dispatchActiveConferenceTask(undefined);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- These are the only changes we care about
    }, [activeConferenceTask, deviceCallSid, effectKey]);
};
