import { useMemo, useState, useEffect, useRef, useCallback } from "react";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import { formatPhoneNumber } from "Services/CommunicationService";
import { selectConference } from "Services/state/conferences";
import { ParticipantInfo, setConferenceDuration } from "Services/state/conferences/ConferenceStateSlice";
import { selectActiveCallTaskContact } from "Services/state/contacts/Selectors/taskSelectors";
import { isPendingInboundConferenceTask } from "Services/Task.service";

import { useCallParticipantActions } from "../Hooks/useCallParticipantActions";
import { CallParticipantLayout, CALL_MESSAGES } from "../Layout/CallParticipantLayout";

import type { RVTask } from "@regal-voice/shared-types";
import type { Agent } from "Types/Agent";

type LegacyCallParticipantProps = {
    agents: Array<Agent>;
    currentWorkerUri: string;
    participant: ParticipantInfo;
    task: RVTask;
};
// seems to be time in seconds (from twilio)
// can this be replaced with something or moved to time service?
export const formatLegacyTime = (timestamp: number) => {
    const timeDiff = dayjs().unix() - timestamp;
    const h = Math.floor((timeDiff % (3600 * 24)) / 3600);
    const m = Math.floor((timeDiff % 3600) / 60);
    const s = Math.floor(timeDiff % 60);
    const prepandZeroToTime = (value: number) => {
        return value < 10 ? `0${value}` : value;
    };
    return `${h ? `${prepandZeroToTime(h)}:` : ""}${m ? prepandZeroToTime(m) : "00"}:${
        s ? prepandZeroToTime(s) : "00"
    }`;
};

// remove when you remove the rolloutUseConferenceStateSse and rolloutUseConferenceStateSseActions flags
export function LegacyCallParticipant({
    agents,
    currentWorkerUri,
    participant,
    task,
}: LegacyCallParticipantProps): JSX.Element {
    const interval = useRef(0);
    const dispatch = useDispatch();

    const { participants, duration, connectionParams } = useSelector(selectConference);
    const shouldDisplayIndependentActionButtons = useMemo(
        () =>
            participants &&
            Object.values(participants).filter(
                (p) => p.phoneNumber != `client:${currentWorkerUri}` && p.managerAction !== "listen"
            ).length >= 2,
        [participants, currentWorkerUri]
    );
    const isContact = participant?.type == "contact";
    const isAgent = !!participant.phoneNumber?.includes("client:");
    const isExternalPhonebook = participant?.type == "external-phonebook";
    const [callStatus, setCallStatus] = useState<string>();

    const {
        holdWrap,
        dropParticipantByCallSid,
        participantIconValue,
        participantTypePlaceholder,
        isHoldDisabled,
        isHangUpDisabled,
        participantConnected,
    } = useCallParticipantActions({
        status: participant.status,
        conferenceSid: participant.conferenceSid as string,
        conferenceFriendlyId: participant.conferenceFriendlyId as string,
        callSid: participant.callSid as string,
        taskSid: connectionParams.taskSid as string,
        participantPhoneNumber: participant.phoneNumber,
        managerAction: participant.managerAction,
        isContact,
        isAgent,
        isExternalPhonebook,
    });

    // only needed when the participant is the contact being reached out to
    const contact = useSelector(selectActiveCallTaskContact);

    const getDefaultConnectionStatus = useCallback(() => {
        return isPendingInboundConferenceTask(task) ? "Ringing..." : "Connecting...";
    }, [task]);

    const formattedName = useMemo(() => {
        if (isAgent) {
            return agents.find((a) => a.twilioContactUri == participant.phoneNumber)?.name;
        } else if (isContact) {
            return contact.name || participant.name;
        } else if (isExternalPhonebook) {
            return participant.name;
        }
        // if not agent nor contact, then it's an external number
        return formatPhoneNumber(participant.to);
    }, [isAgent, isContact, isExternalPhonebook, participant, agents, contact.name]);

    useEffect(() => {
        if (isEmpty(participants)) {
            setCallStatus(getDefaultConnectionStatus());
            return;
        }
        const participantsArray = Object.values(participants);
        if (participantsArray.length < 1) {
            setCallStatus("Connecting...");
        } else if (participant.status == "ringing") {
            setCallStatus("Ringing...");
        } else if (participant.status == "in-progress") {
            setCallStatus("Live call");
        } else {
            setCallStatus("Connecting...");
        }
    }, [
        participants
            ? Object.values(participants)
                  .map((p) => p.status)
                  .join(",")
            : [],
    ]);

    useEffect(() => {
        if (!participantConnected) {
            setConferenceDuration({ duration: 0 });
            window.clearInterval(interval.current);
            // only kick off if we dont have a duration. so we only kick for the first participant
        } else if (participantConnected && !duration) {
            if (!interval.current && participant?.startTimestamp) {
                interval.current = window.setInterval(() => {
                    // bug bash clean up, we should really know if we have the participant or not
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    dispatch(setConferenceDuration({ duration: dayjs().unix() - participant.startTimestamp! }));
                }, 1000);
            }
        }
    }, [participantConnected, duration, participant.startTimestamp, dispatch]);

    // can we move this into the above
    useEffect(() => {
        return () => {
            if (interval.current) {
                window.clearInterval(interval.current);
                interval.current = 0;
            }
        };
    }, []);

    const formattedTime = useMemo(() => {
        // we should really know if we have the participant or not, but we don't
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return formatLegacyTime(participant.startTimestamp!);
        // this is a bit weird but its how it was working. should probably be refactored
        // into a useState but its going away soon enough
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration]);

    return (
        <CallParticipantLayout
            participantName={participant.name}
            isContact={isContact}
            formattedName={formattedName}
            participantIcon={participantIconValue}
            participantTypePlaceholder={participantTypePlaceholder}
            callStatus={callStatus}
            participantConnected={participantConnected}
            formattedTime={formattedTime}
            shouldDisplayIndependentActionButtons={shouldDisplayIndependentActionButtons}
            isParticipantOnHold={participant.onHold}
            onHoldWrap={holdWrap}
            onHangUp={dropParticipantByCallSid}
            isHangUpDisabled={isHangUpDisabled}
            holdMessages={CALL_MESSAGES.hold}
            isHoldDisabled={isHoldDisabled}
            hangUpMessages={CALL_MESSAGES.hangUp}
        />
    );
}
