import { useSelector } from "react-redux";

import { useLegacyConferenceEvents } from "Pages/agentDesktop/agentInterface/CallPanel/ActiveCall/hooks/useLegacyConferenceEvents";
import { useFlags } from "Services/FeatureFlagService";
import { selectConferenceSnapshotAgentCallSid } from "Services/state/conferences/ConferenceSnapshotSelectors";
import { selectActiveConferenceTask, selectConference } from "Services/state/conferences/Selectors";
import { selectActiveAction } from "Services/state/manager-action/Selectors";
import { isPendingInboundConferenceTask } from "Services/Task.service";

import { CallBarLayout, IncomingCallBarButtons, ActiveCallBarButtons } from "./components";
import { useBarControlsVisibility, useWrapListener, useLegacyActiveCallEvents, useCallActionAcceptTask } from "./hooks";

const useCallBarLegacyData = () => {
    const activeConferenceTask = useSelector(selectActiveConferenceTask);
    const activeManagerAction = useSelector(selectActiveAction);

    const {
        deviceCallSid,
        connectionParams: { Params },
    } = useSelector(selectConference);

    const visible = useBarControlsVisibility({ activeConferenceTask, deviceCallSid });
    useWrapListener();
    useLegacyActiveCallEvents({ activeConferenceTask });

    const callParamsHasManagerAction = Params && Params.includes("managerAction");

    useLegacyConferenceEvents({
        name: activeConferenceTask?.attributes?.name,
        contactPhone: activeConferenceTask?.attributes?.contactPhone,
        regalVoicePhone: activeConferenceTask?.attributes?.regalVoicePhone,
        deviceCallSid,
    });
    const { acceptActiveTask, acceptingCall } = useCallActionAcceptTask({ activeConferenceTask, deviceCallSid });

    const hideCallBar = !visible || activeManagerAction || callParamsHasManagerAction;
    const isIncomingCall = isPendingInboundConferenceTask(activeConferenceTask);

    return {
        hideCallBar,
        isIncomingCall,
        activeConferenceTask,
        deviceCallSid,
        acceptActiveTask,
        acceptingCall,
        // the snapshot needs these two values, so we can move them into the main hook
        // when we clean this and the connectionParams up
        activeManagerAction,
        Params,
    };
};

const useCallBarData = () => {
    const { rolloutUseConferenceStateSseActions } = useFlags();

    const {
        hideCallBar: legacyHideCallBar,
        activeConferenceTask: legacyActiveConferenceTask,
        acceptActiveTask: legacyAcceptActiveTask,
        acceptingCall: legacyAcceptingCall,
        isIncomingCall: legacyIsIncomingCall,
        activeManagerAction,
        Params,
    } = useCallBarLegacyData();

    // just the normal conference version
    const snapshotActiveConferenceTask = useSelector(selectActiveConferenceTask);
    const snapshotIsIncomingCall = isPendingInboundConferenceTask(snapshotActiveConferenceTask);
    const agentCallSid = useSelector(selectConferenceSnapshotAgentCallSid);
    const snapshotVisible = useBarControlsVisibility({
        activeConferenceTask: snapshotActiveConferenceTask,
        deviceCallSid: agentCallSid,
    });

    const callParamsHasManagerAction = Params && Params.includes("managerAction");
    const snapshotHideCallBar = !snapshotVisible || !!activeManagerAction || callParamsHasManagerAction;

    const { acceptActiveTask: snapshotAcceptActiveTask, acceptingCall: snapshotAcceptingCall } =
        useCallActionAcceptTask({
            activeConferenceTask: snapshotActiveConferenceTask,
            deviceCallSid: agentCallSid,
        });

    const hideCallBar = rolloutUseConferenceStateSseActions ? snapshotHideCallBar : legacyHideCallBar;
    const isIncomingCall = rolloutUseConferenceStateSseActions ? snapshotIsIncomingCall : legacyIsIncomingCall;
    const activeConferenceTask = rolloutUseConferenceStateSseActions
        ? snapshotActiveConferenceTask
        : legacyActiveConferenceTask;
    const acceptActiveTask = rolloutUseConferenceStateSseActions ? snapshotAcceptActiveTask : legacyAcceptActiveTask;
    const acceptingCall = rolloutUseConferenceStateSseActions ? snapshotAcceptingCall : legacyAcceptingCall;

    return {
        hideCallBar,
        isIncomingCall,
        activeConferenceTask,
        acceptActiveTask,
        acceptingCall,
    };
};

export function CallBar(): JSX.Element {
    const { hideCallBar, activeConferenceTask, acceptActiveTask, acceptingCall, isIncomingCall } = useCallBarData();
    if (hideCallBar) {
        return <></>;
    }

    return (
        <CallBarLayout hideElementsOnSmallScreen={!isIncomingCall}>
            {isIncomingCall ? (
                <IncomingCallBarButtons
                    activeTask={activeConferenceTask}
                    acceptActiveTask={acceptActiveTask}
                    acceptingCall={acceptingCall}
                />
            ) : (
                <ActiveCallBarButtons />
            )}
        </CallBarLayout>
    );
}
