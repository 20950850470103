import { useCallback, useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { useFlags } from "Services/FeatureFlagService";
import { selectSnapshotIsPowerDialTaskWaiting } from "Services/state/conferences/ConferenceSnapshotSelectors";
import { selectIsPowerDialTaskWaiting } from "Services/state/conferences/Selectors";
import { useRVDispatch } from "Services/state/Storage";
import { acceptTaskThunk, setActiveTask } from "Services/state/tasks/Thunks";

import { logger } from "../../CallBar.utils";

import type { RVTask } from "@regal-voice/shared-types";

export type UseCallActionAcceptTaskOptions = {
    activeConferenceTask?: RVTask;
    deviceCallSid?: string;
};

export type UseCallActionAcceptTaskResult = {
    acceptTask: () => void;
    acceptActiveTask: () => void;
    acceptingCall: boolean;
};

export const useCallActionAcceptTask = ({
    activeConferenceTask,
    deviceCallSid,
}: UseCallActionAcceptTaskOptions = {}): UseCallActionAcceptTaskResult => {
    const { multipleCalls, rolloutUseConferenceStateSseActions } = useFlags();

    const dispatch = useRVDispatch();
    const [acceptingCall, setAcceptingCall] = useState(false);
    const alreadyAcceptedTaskSids = useRef<string[]>([]);

    // legacy
    const legacyIsPowerDialTaskWaiting = useSelector(selectIsPowerDialTaskWaiting);
    // snapshit
    const snapshotIsPowerDialTaskWaiting = useSelector(selectSnapshotIsPowerDialTaskWaiting);

    // check
    const isPowerDialTaskWaiting = rolloutUseConferenceStateSseActions
        ? snapshotIsPowerDialTaskWaiting
        : legacyIsPowerDialTaskWaiting;
    const isAgentDesktopPage = useRouteMatch("/agent/:sub?");

    const acceptTask = useCallback(() => {
        if (!activeConferenceTask || alreadyAcceptedTaskSids.current?.includes(activeConferenceTask.sid)) {
            return;
        }

        setAcceptingCall(true);
        alreadyAcceptedTaskSids.current.push(activeConferenceTask.sid);
        dispatch(acceptTaskThunk(activeConferenceTask, { multipleCalls }));
    }, [activeConferenceTask, dispatch, setAcceptingCall, alreadyAcceptedTaskSids, multipleCalls]);

    const acceptActiveTask = useCallback(() => {
        dispatch(setActiveTask(activeConferenceTask));
        acceptTask();
    }, [dispatch, activeConferenceTask, acceptTask]);

    useEffect(() => {
        const shouldAcceptPowerDialTask = isPowerDialTaskWaiting && !isAgentDesktopPage;

        if (shouldAcceptPowerDialTask) {
            acceptTask();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to trigger when there's a task waiting
    }, [isPowerDialTaskWaiting]);

    useEffect(() => {
        if (deviceCallSid && activeConferenceTask?.status === "accepted") {
            logger.log("Call controls switched from inactive to active", {
                connectionCallSid: deviceCallSid,
            });

            setAcceptingCall(false);
        }
        // We only care about the `deviceCallSid` changing, not the flag.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceCallSid]);

    return {
        acceptTask,
        acceptActiveTask,
        acceptingCall,
    };
};
