import { isCallTask } from "Components/shared/AgentsDropdown/helpers";
import { addEventEmitterListener } from "Services/server-sent-events/EventEmitter";

import { selectAgentInformation } from "../agent/AgentInformationSlice";
import { reduxStore } from "../Storage";
import { selectActiveTask } from "./Selectors";
import {
    handleReservationAccepted,
    handleReservationCreated,
    backfillExistingActiveTasks,
    handleReservationCanceled,
    handleReservationCompleted,
    handleReservationRejected,
    handleReservationRescinded,
    handleReservationTimedOut,
    handleReservationWrapup,
    handleTaskUpdate,
} from "./Thunks";
import { mapReservationCreatedSSEToRVTask } from "./Utils";

import type { MessageType } from "@regal-voice/shared-types";
import type { Device } from "@twilio/voice-sdk";
import type { FlagTypes } from "Application/thirdParty/launchDarkly";

export type ReservationCreatedSSETaskData = Required<MessageType["twilio"]["reservation.created"]["task"]> & {
    reservationCreatedAt: string;
    reservationUpdatedDate: string;
    // https://regalvoice.atlassian.net/browse/RD-10225
    // for progressive dialer tasks we send one combined "reservation.created" SSE event
    // with the created and accepted reservation data
    // rather than sending a reservation.created and a reservation.accepted SSE messages that could arrive out of order.
    // so for these tasks we populate the "accepted at" from the "created" event (since there will be no "accepted" one)
    reservationAcceptedAt?: string;
};

export function dispatchHandleReservationCreated(
    data: MessageType["twilio"]["reservation.created"],
    { multipleCalls, blockAutoAcceptedCallsWhenAgentIsOnActiveCall }: Partial<FlagTypes>,
    device: Device | undefined,
    fetchNewDeviceToken: () => Promise<void>
): void {
    const rvTask = mapReservationCreatedSSEToRVTask(data.task as ReservationCreatedSSETaskData);

    if (blockAutoAcceptedCallsWhenAgentIsOnActiveCall && isCallTask({ task: rvTask }) && rvTask.attributes.autoAnswer) {
        const state = reduxStore.getState();
        const activeTask = selectActiveTask(state);
        const agent = selectAgentInformation(state);

        if (
            activeTask && // there is already an active task
            isCallTask({ task: activeTask }) && // and it's a call task
            activeTask.status === "accepted" && // and it's accepted and assigned to the agent
            activeTask.taskStatus === "assigned" &&
            !agent.isAiAgent // and the agent is not an AI agent
        ) {
            // then prevent this new task from interrupting the active one
            rvTask.attributes.autoAnswer = false;
        }
    }

    reduxStore.dispatch(
        handleReservationCreated({ task: rvTask, flags: { multipleCalls }, device, fetchNewDeviceToken })
    );
}

function dispatchHandleReservationAccepted(
    {
        reservation: { sid: reservationSid },
        task: { reservationAcceptedAt } = {},
    }: MessageType["twilio"]["reservation.accepted"],
    device: Device | undefined,
    fetchNewDeviceToken: () => Promise<void>
) {
    reduxStore.dispatch(handleReservationAccepted(device, fetchNewDeviceToken, reservationSid, reservationAcceptedAt));
}

function dispatchHandleReservationCanceled({
    reservation: { sid: reservationSid },
    task,
}: MessageType["twilio"]["reservation.canceled"]) {
    reduxStore.dispatch(handleReservationCanceled(reservationSid, task));
}

function dispatchHandleReservationCompleted({
    reservation: { sid: reservationSid },
}: MessageType["twilio"]["reservation.completed"]) {
    reduxStore.dispatch(handleReservationCompleted(reservationSid));
}

function dispatchHandleReservationRejected({
    reservation: { sid: reservationSid },
}: MessageType["twilio"]["reservation.rejected"]) {
    reduxStore.dispatch(handleReservationRejected(reservationSid));
}

function dispatchHandleReservationRescinded({
    reservation: { sid: reservationSid },
}: MessageType["twilio"]["reservation.rescinded"]) {
    reduxStore.dispatch(handleReservationRescinded(reservationSid));
}

function dispatchHandleReservationTimedOut({
    reservation: { sid: reservationSid },
}: MessageType["twilio"]["reservation.timeout"]) {
    reduxStore.dispatch(handleReservationTimedOut(reservationSid));
}

function dispatchHandleReservationWrapup({
    task: { reservationUpdatedDate },
    reservation: { sid: reservationSid },
}: MessageType["twilio"]["reservation.wrapup"]) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    reduxStore.dispatch(handleReservationWrapup({ reservationSid, reservationUpdatedDate: reservationUpdatedDate! }));
}

function dispatchHandleTaskUpdate({ task }: MessageType["twilio"]["task.updated"]) {
    reduxStore.dispatch(handleTaskUpdate(task));
}

type SseListenerProps = {
    flags: {
        multipleCalls?: boolean;
        blockAutoAcceptedCallsWhenAgentIsOnActiveCall?: boolean;
    };
    device: Device | undefined;
    fetchNewDeviceToken: () => Promise<void>;
};

/**
 * Sets up listeners for all task SSEs and returns a function to unsubscribe them
 */
export function subscribeToTaskSSE({
    flags: { multipleCalls, blockAutoAcceptedCallsWhenAgentIsOnActiveCall },
    device,
    fetchNewDeviceToken,
}: SseListenerProps): () => void {
    const dispatch = reduxStore.dispatch;

    dispatch(backfillExistingActiveTasks({}));

    const unsubscribers: Array<() => void> = [];

    unsubscribers.push(
        addEventEmitterListener("twilio", "reservation.created", (data: MessageType["twilio"]["reservation.created"]) =>
            dispatchHandleReservationCreated(
                data,
                { multipleCalls, blockAutoAcceptedCallsWhenAgentIsOnActiveCall },
                device,
                fetchNewDeviceToken
            )
        )
    );

    unsubscribers.push(
        addEventEmitterListener(
            "twilio",
            "reservation.accepted",
            (data: MessageType["twilio"]["reservation.accepted"]) =>
                dispatchHandleReservationAccepted(data, device, fetchNewDeviceToken)
        )
    );
    unsubscribers.push(addEventEmitterListener("twilio", "reservation.canceled", dispatchHandleReservationCanceled));

    unsubscribers.push(addEventEmitterListener("twilio", "reservation.completed", dispatchHandleReservationCompleted));

    unsubscribers.push(addEventEmitterListener("twilio", "reservation.rejected", dispatchHandleReservationRejected));

    unsubscribers.push(addEventEmitterListener("twilio", "reservation.rescinded", dispatchHandleReservationRescinded));

    unsubscribers.push(addEventEmitterListener("twilio", "reservation.timeout", dispatchHandleReservationTimedOut));

    unsubscribers.push(addEventEmitterListener("twilio", "reservation.wrapup", dispatchHandleReservationWrapup));

    unsubscribers.push(addEventEmitterListener("twilio", "task.updated", dispatchHandleTaskUpdate));

    return () => {
        unsubscribers.forEach((unsubscriber) => unsubscriber());
    };
}
