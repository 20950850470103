import { useMemo, useCallback } from "react";

import { useMutation } from "@apollo/client";

import { cancelTasks } from "Services/marketing-api/tasks/mutations";
import { ParticipantInfo } from "Services/state/conferences/ConferenceStateSlice";
import { Agent } from "Types/Agent";

import { PendingParticipantLayout, PENDING_CANCEL_REASONS, PENDING_MESSAGES } from "../Layout/PendingParticipantLayout";

export function LegacyPendingParticipant({
    agents,
    participant,
}: {
    agents: Agent[];
    participant: ParticipantInfo;
}): JSX.Element {
    const [cancelTaskList] = useMutation<{ cancelTasks: string[] }>(cancelTasks);
    const cancelTransfer = useCallback(async () => {
        await cancelTaskList({
            variables: {
                // bug bash clean up, we need to handle not having a task before we get here
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ids: [participant!.taskSid],
                cancelAttributes: PENDING_CANCEL_REASONS,
            },
        });
    }, [cancelTaskList, participant]);

    const isQueue = participant.queueName !== undefined;
    const participantName = useMemo(() => {
        if (participant.phoneNumber) {
            return agents.find((a) => a.twilioContactUri == participant.phoneNumber)?.name;
        } else if (participant.queueName) {
            return participant.queueName;
        }
    }, [agents, participant.phoneNumber, participant.queueName]);

    return (
        <PendingParticipantLayout
            participantName={participantName}
            isQueue={isQueue}
            cancelTransfer={cancelTransfer}
            messages={PENDING_MESSAGES}
        />
    );
}
