import { useSelector } from "react-redux";

import { useFlags } from "Services/FeatureFlagService";
import {
    selectConferenceSnapshotContactParticipant,
    selectConferenceSnapshotActiveParticipants,
} from "Services/state/conferences/ConferenceSnapshotSelectors";

import { useMainParticipant } from "./Hooks/useMainParticipant";
import { useOtherParticipants } from "./Hooks/useOtherParticipants";

import type { RVTask } from "@regal-voice/shared-types";

export type UseConferenceParticipantsOptions = {
    task?: RVTask;
};

export const useConferenceParticipants = ({ task }: UseConferenceParticipantsOptions = {}) => {
    const { rolloutUseConferenceStateSse } = useFlags();
    // this is the legacy way of getting the participants
    // we can remove this once we have fully switched over to the flag rolloutUseConferenceStateSse
    const legacyParticipants = useOtherParticipants({ task });
    const legacyParticipant = useMainParticipant({ participants: legacyParticipants })?.participant;

    // new immutable snapshots from the backend
    const snapshotContactParticipant = useSelector(selectConferenceSnapshotContactParticipant);
    const snapshotActiveParticipants = useSelector(selectConferenceSnapshotActiveParticipants);

    // flag to determine which one to use
    const activeParticipants = rolloutUseConferenceStateSse ? snapshotActiveParticipants : legacyParticipants;
    const contactParticipant = rolloutUseConferenceStateSse ? snapshotContactParticipant : legacyParticipant;

    return {
        activeParticipants,
        contactParticipant,
    };
};
