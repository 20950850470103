import { useMemo } from "react";

import { useSelector } from "react-redux";

import { useAgentPhoneNumberDetails } from "Hooks/useAgentPhoneNumberDetails";
import { formatPhoneNumber } from "Services/CommunicationService";
import { selectActiveConferenceTask } from "Services/state/conferences/Selectors";
import { getAttributes } from "Services/Task.service";

import styles from "../CallBar.module.scss";

const TASK_DYNAMIC_PHONE_NUMBER = "<dynamic>";

export function CallBarLine(): JSX.Element {
    const task = useSelector(selectActiveConferenceTask);
    const { regalVoicePhone } = getAttributes(task);
    const regalVoicePhoneSource = useAgentPhoneNumberDetails(regalVoicePhone);
    const regalVoicePhoneFriendlyName = regalVoicePhoneSource?.internalDisplayName || "";
    const phoneNumber = useMemo(() => {
        if (regalVoicePhone === TASK_DYNAMIC_PHONE_NUMBER) {
            return "Loading...";
        }

        return formatPhoneNumber(regalVoicePhone);
    }, [regalVoicePhone]);

    if (!task) {
        return <></>;
    }

    if (!regalVoicePhoneFriendlyName) {
        return <span className={styles.textTitle}>{phoneNumber}</span>;
    }

    return (
        <>
            <span className={styles.textTitle}>{regalVoicePhoneFriendlyName}</span>
            <span>{phoneNumber}</span>
        </>
    );
}
