import { useCallback } from "react";

import { useSelector } from "react-redux";

import { manageRecording } from "Services/ConversationsApiService";
import { useFlags } from "Services/FeatureFlagService";
import { setConferenceRecording } from "Services/state/conferences";
import {
    selectIsConferenceRecording,
    selectSnapshotConferenceFriendlyID,
    selectSnapShotConferenceSid,
    selectConferenceSnapshotAgentCallSid,
} from "Services/state/conferences/ConferenceSnapshotSelectors";
import { toggleSnapshotRecording as toggleRecordingAction } from "Services/state/conferences/ConferenceSnapshotSlice";
import { selectConference } from "Services/state/conferences/Selectors";
import { useRVDispatch } from "Services/state/Storage";

import { logger } from "../../CallBar.utils";

export type UseRecordCallResult = { isRecording: boolean; toggleRecording: () => void };

// remove when you remove the rolloutUseConferenceStateSse and rolloutUseConferenceStateSseActions flags
const useLegacyRecordCall = (): UseRecordCallResult => {
    const dispatch = useRVDispatch();
    const { isRecording = false, conferenceSid, deviceCallSid, conferenceFriendlyId } = useSelector(selectConference);

    const toggleRecording = useCallback(() => {
        if (!deviceCallSid || !conferenceFriendlyId) {
            logger.error("Tried to toggle recording but no active call found", {
                deviceCallSid,
                conferenceSid,
                conferenceFriendlyId,
            });

            return;
        }

        const [nextStatus, nextState] = isRecording ? (["paused", false] as const) : (["in-progress", true] as const);

        return manageRecording({
            callSid: deviceCallSid,
            status: nextStatus,
            conferenceSid,
            conferenceFriendlyId,
        }).then(() => dispatch(setConferenceRecording({ isRecording: nextState })));
    }, [deviceCallSid, conferenceFriendlyId, isRecording, conferenceSid, dispatch]);

    return { isRecording, toggleRecording };
};

export const useCallActionRecord = (): UseRecordCallResult => {
    const dispatch = useRVDispatch();

    // flag
    const { rolloutUseConferenceStateSseActions } = useFlags();

    // legacy
    const { isRecording: isLegacyRecording, toggleRecording: toggleLegacyRecording } = useLegacyRecordCall();

    // snapshot
    const isSnapshotRecording = useSelector(selectIsConferenceRecording);
    const callSid = useSelector(selectConferenceSnapshotAgentCallSid);
    const conferenceFriendlyId = useSelector(selectSnapshotConferenceFriendlyID);
    const conferenceSid = useSelector(selectSnapShotConferenceSid);

    const toggleSnapshotRecording = useCallback(() => {
        if (!callSid || !conferenceFriendlyId) {
            logger.error("Tried to toggle recording but no active call found", {
                callSid,
                conferenceSid,
                conferenceFriendlyId,
            });

            return;
        }

        const [nextStatus] = isSnapshotRecording ? (["paused", false] as const) : (["in-progress", true] as const);

        return manageRecording({
            callSid,
            status: nextStatus,
            conferenceSid,
            conferenceFriendlyId,
        }).then(() => dispatch(toggleRecordingAction()));
    }, [callSid, conferenceFriendlyId, isSnapshotRecording, conferenceSid, dispatch]);

    // check
    const isRecording = rolloutUseConferenceStateSseActions ? isSnapshotRecording : isLegacyRecording;
    const toggleRecording = rolloutUseConferenceStateSseActions ? toggleSnapshotRecording : toggleLegacyRecording;

    return { isRecording, toggleRecording };
};
