import { useMemo } from "react";

import { useSelector } from "react-redux";

import { useFlags } from "Services/FeatureFlagService";
import { selectWorkerUri } from "Services/state/agent/AgentInformationSlice";
import { selectConference } from "Services/state/conferences";
import {
    selectConferenceSnapshotActiveParticipants,
    selectConferenceSnapshotSelf,
} from "Services/state/conferences/ConferenceSnapshotSelectors";
import { CallStatusEvent } from "Services/Task.service";
export type UseBarActionsStateResult = {
    availableActions: {
        disableIndependentActions: boolean;
        disableActions: boolean;
    };
};

export const useBarActionsLegacyState = (): UseBarActionsStateResult => {
    const workerUri = useSelector(selectWorkerUri);
    const { deviceCallSid, participants } = useSelector(selectConference);
    const availableActions = useMemo(() => {
        const result = {
            disableIndependentActions: false,
            disableActions: false,
        };
        if (!participants) {
            return result;
        }

        const participantsList = Object.values(participants);
        const participantsJoined = participantsList.some(({ status }) => status == CallStatusEvent.IN_PROGRESS);
        result.disableActions = !participantsJoined && !deviceCallSid;

        result.disableIndependentActions =
            workerUri !== "" &&
            participantsList.filter(
                ({ phoneNumber, managerAction }) => phoneNumber !== `client:${workerUri}` && managerAction !== "listen"
            ).length >= 2;

        return result;
    }, [participants, deviceCallSid, workerUri]);

    return { availableActions };
};

export const useBarActionsState = (): UseBarActionsStateResult => {
    // flag
    const { rolloutUseConferenceStateSseActions } = useFlags();

    // legacy
    const { availableActions: legacyAvailableActions } = useBarActionsLegacyState();

    // snapshot
    const { callSid: agentCallSid } = useSelector(selectConferenceSnapshotSelf) || {};
    const activeParticipants = useSelector(selectConferenceSnapshotActiveParticipants);

    const snapshotActions = useMemo(() => {
        const result = {
            disableIndependentActions: false,
            disableActions: false,
        };
        if (!activeParticipants || activeParticipants.length === 0) {
            return result;
        }

        const participantsJoined = activeParticipants.some(({ status }) => status == CallStatusEvent.IN_PROGRESS);
        result.disableActions = !participantsJoined && !agentCallSid;

        result.disableIndependentActions = activeParticipants.length >= 2;

        return result;
    }, [activeParticipants, agentCallSid]);

    const availableActions = rolloutUseConferenceStateSseActions ? snapshotActions : legacyAvailableActions;

    return {
        availableActions,
    };
};
