import { useCallback } from "react";

import { ConferenceParticipant } from "@regal-voice/shared-types";
import { Tooltip } from "antd";
import { clsx } from "clsx";
import { useSelector } from "react-redux";

import Icon, { IconSizes } from "Components/elements/Icon";
import { useFlags } from "Services/FeatureFlagService";
import { selectConferenceSnapshotIsConnected } from "Services/state/conferences/ConferenceSnapshotSelectors";
import { ParticipantInfo, setConferenceDuration } from "Services/state/conferences/ConferenceStateSlice";
import { selectDeviveCallConnected, selectActiveConferenceTask } from "Services/state/conferences/Selectors";
import { useRVDispatch } from "Services/state/Storage";

import { useNavigateToTask, useCallActionRecord, useLineInfo, useCallTitle } from "../hooks";
import { useConferenceParticipants } from "../hooks/useConferenceParticipants/useConferenceParticipants";
import { CallStatus, type CallStatusUpdateDurationFn } from "./CallStatus";

import styles from "../CallBar.module.scss";

export type CallTaskInfoProps = {
    onInfoUnavailable?: () => void;
    updateDuration?: boolean;
};

export const CallTaskInfo = ({ onInfoUnavailable, updateDuration }: CallTaskInfoProps): JSX.Element => {
    const dispatch = useRVDispatch();

    const { rolloutUseConferenceStateSseActions } = useFlags();

    // legacy
    const legacyConnected = useSelector(selectDeviveCallConnected);
    // snapshot
    const snapshotConnected = useSelector(selectConferenceSnapshotIsConnected);

    // check
    const connected = rolloutUseConferenceStateSseActions ? snapshotConnected : legacyConnected;

    const task = useSelector(selectActiveConferenceTask);
    const { isRecording } = useCallActionRecord();

    const lineInfo = useLineInfo({ task });
    const navigateToTask = useNavigateToTask({ task });
    const { contactParticipant } = useConferenceParticipants({ task });
    const callTitle = useCallTitle({
        // todo remove ParticipantInfo once we remove the rolloutUseConferenceStateSseActions
        participant: contactParticipant as ConferenceParticipant<"contact"> | ParticipantInfo | undefined,
        task,
    });

    const dispatchDuration = useCallback<CallStatusUpdateDurationFn>(
        (newDuration) => {
            if (!updateDuration) {
                return;
            }

            // we only want to set this for the old way
            // hopefully we remove this soon.
            if (!rolloutUseConferenceStateSseActions) {
                dispatch(setConferenceDuration({ duration: newDuration }));
            }
        },
        [dispatch, updateDuration, rolloutUseConferenceStateSseActions]
    );

    if (!task || !lineInfo) {
        onInfoUnavailable?.();
        return <span className={styles.textTitle}>Information Unavailable</span>;
    }

    return (
        <>
            <span className={clsx(styles.textTitle, styles.titleClassName)}>
                <Tooltip title={callTitle} placement="top">
                    <button type="button" className={styles.textActionTitle} onClick={navigateToTask}>
                        {callTitle}
                    </button>
                </Tooltip>
                {" | "}
                <CallStatus activeConferenceTask={task} updateDuration={dispatchDuration} />
            </span>
            {connected && (
                <span>
                    {isRecording ? (
                        <>
                            <Icon icon="resume-recording" size={IconSizes.XXSMALL} color="red-4" /> Recording
                        </>
                    ) : (
                        "Recording stopped"
                    )}
                </span>
            )}
        </>
    );
};
