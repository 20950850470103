import { useEffect, useMemo, useRef } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { useFlags } from "Services/FeatureFlagService";
import { selectConference } from "Services/state/conferences";
import { CallStatusEvent, isPendingInboundConferenceTask } from "Services/Task.service";

import { formatTimeForParticipant } from "../../CallPanel/TaskInfo/CallParticipant/CallParticipant";
import { useConferenceParticipants } from "../hooks/useConferenceParticipants/useConferenceParticipants";

import type { RVTask } from "@regal-voice/shared-types";

const formatDuration = (duration: number): string => {
    const formatted = dayjs.duration(duration).format("HH:mm:ss");
    const [hr, min, sec] = formatted.split(":");
    if (hr === "00") {
        return `${min}:${sec}`;
    }

    return formatted;
};

export type CallStatusUpdateDurationFn = (newDuration: number) => void;

export type CallStatusProps = {
    activeConferenceTask?: RVTask;
    updateDuration?: CallStatusUpdateDurationFn;
};

export function CallStatus({ activeConferenceTask, updateDuration }: CallStatusProps): JSX.Element {
    const { rolloutUseConferenceStateSse } = useFlags();
    const { activeParticipants, contactParticipant } = useConferenceParticipants({
        task: activeConferenceTask,
    });

    // remove when we remove rolloutUseConferenceStateSse and rolloutUseConferenceStateSseActions
    const { duration } = useSelector(selectConference);
    const connected = contactParticipant?.status == CallStatusEvent.IN_PROGRESS;
    const elementRef = useRef<HTMLDivElement>(null);
    const intervalRef = useRef<number>(0);
    const updateDurationRef = useRef(updateDuration);

    const statusText = useMemo(() => {
        if (!activeParticipants?.length) {
            return activeConferenceTask && isPendingInboundConferenceTask(activeConferenceTask)
                ? "Ringing..."
                : "Connecting...";
        }

        const status = contactParticipant?.status;

        if (status === "ringing" || status == "queued") {
            return "Ringing...";
        }

        if (status === "in-progress") {
            return "00:00";
        }

        return "Connecting...";
    }, [activeConferenceTask, activeParticipants, contactParticipant]);

    useEffect(() => {
        updateDurationRef.current = updateDuration;
    }, [updateDuration]);

    useEffect(() => {
        if (!connected) {
            updateDurationRef.current?.(0);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = 0;
            }
        } else if (duration === 0) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = 0;
            }

            const intervalFn = () => {
                const target = elementRef.current;
                if (!target) {
                    return;
                }
                // @ts-expect-error this will be fixed when we remove the rollout flag
                const startTimestamp = contactParticipant?.startTimestamp || contactParticipant?.joinedAt || 0;
                if (typeof startTimestamp !== "number") {
                    return;
                }

                if (rolloutUseConferenceStateSse) {
                    const duration = formatTimeForParticipant(startTimestamp);
                    updateDurationRef.current?.(startTimestamp);
                    target.innerText = duration;
                } else {
                    const duration = dayjs().unix() - startTimestamp;
                    updateDurationRef.current?.(duration);
                    target.innerText = formatDuration(duration * 1000); // twillio times are in seconds so we need to multiply by 1000
                }
            };

            intervalRef.current = window.setInterval(intervalFn, 1000);
        }
    }, [connected, duration, contactParticipant, rolloutUseConferenceStateSse]);

    return <span ref={elementRef}>{statusText}</span>;
}
