import { useCallback } from "react";

import { ManagerCallAction } from "@regal-voice/shared-types";

import { formatPhoneNumber } from "Services/CommunicationService";
import { dropParticipant, holdParticipant } from "Services/ConversationsApiService";
import { CallStatusEvent } from "Services/Task.service";

const participantIcon = (isContact: boolean, isAgent: boolean) => {
    if (isContact) {
        return "silhouette";
    } else if (isAgent) {
        return "headset";
    } else {
        return "external-transfer";
    }
};

export const determineParticipantPlaceholder = (
    isContact: boolean,
    managerAction: ManagerCallAction | undefined,
    participantPhoneNumber: string | undefined,
    isAgent: boolean,
    isExternalPhonebook: boolean
) => {
    if (isContact) {
        return formatPhoneNumber(participantPhoneNumber);
    } else if (managerAction === "barge") {
        return "Barging";
    } else if (isAgent) {
        return "Internal Transfer";
    } else if (isExternalPhonebook) {
        return formatPhoneNumber(participantPhoneNumber);
    } else {
        return "External Transfer";
    }
};

type Props = {
    status?: CallStatusEvent;
    conferenceSid?: string;
    conferenceFriendlyId?: string;
    callSid: string;
    taskSid: string;
    isContact: boolean;
    isAgent: boolean;
    isExternalPhonebook: boolean;
    participantPhoneNumber?: string;
    managerAction?: ManagerCallAction;
};
export const useCallParticipantActions = ({
    status,
    conferenceSid,
    conferenceFriendlyId,
    callSid,
    taskSid,
    isContact,
    isAgent,
    isExternalPhonebook,
    participantPhoneNumber,
    managerAction,
}: Props) => {
    const participantConnected = status == CallStatusEvent.IN_PROGRESS;
    const participantIconValue = participantIcon(isContact, isAgent);

    const isHoldDisabled = Boolean(managerAction);
    const isHangUpDisabled = Boolean(managerAction);

    const participantTypePlaceholder = determineParticipantPlaceholder(
        isContact,
        managerAction,
        participantPhoneNumber,
        isAgent,
        isExternalPhonebook
    );

    const holdWrap = useCallback(
        (hold: boolean) => {
            return holdParticipant({
                conferenceSid,
                conferenceFriendlyId,
                callSid,
                // we send the inverse of this boolean because we are dumb
                // and are sending the opposite of what we actually want
                hold: !hold,
            });
        },
        [conferenceSid, conferenceFriendlyId, callSid]
    );

    const dropParticipantByCallSid = useCallback(
        // not used, but we need it or the consumer complains
        (_typeParity: boolean) => {
            return dropParticipant({
                conferenceSid: conferenceSid as string,
                callSid: callSid as string,
                taskSid: taskSid as string,
            });
        },
        [conferenceSid, callSid, taskSid]
    );

    return {
        participantConnected,
        isHoldDisabled,
        isHangUpDisabled,
        participantTypePlaceholder,
        holdWrap,
        dropParticipantByCallSid,
        participantIconValue,
    };
};
