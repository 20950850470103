import { clsx } from "clsx";

import Icon, { IconSizes, IconVariants } from "Components/elements/Icon";
import CallActionButton from "Pages/agentDesktop/agentInterface/CallPanel/ActiveCall/CallActionButton/CallActionButton";

import styles from "./CallParticipantLayout.module.scss";

export const CALL_MESSAGES = {
    hold: {
        title: (isActive: boolean) => (isActive ? "Unhold" : "Hold"),
        success: (isActive: boolean) => `Call ${isActive ? "on hold" : "resumed"}`,
        error: (err: string, isActive?: boolean) =>
            `An error occured while ${isActive ? "pausing" : "resuming"} the recoding - ${err}`,
    },
    hangUp: {
        title: () => "Hang Up",
        error: (err: string) => `An error occured while wrapping the call - ${err}`,
    },
};

export type ParticipantLayoutProps = {
    participantName?: string;
    participantIcon: string;
    isContact: boolean;
    formattedName?: string;
    participantTypePlaceholder: string;
    callStatus?: string;
    participantConnected?: boolean;
    formattedTime?: string;
    shouldDisplayIndependentActionButtons?: boolean;
    isParticipantOnHold?: boolean;
    onHoldWrap: (state: boolean) => void | Promise<any>;
    onHangUp: (state: boolean) => void | Promise<any>;
    isHoldDisabled?: boolean;
    isHangUpDisabled?: boolean;
    holdMessages: {
        title: (isActive: boolean) => string;
        success?: (isActive: boolean) => string;
        error: (err: string, isActive?: boolean) => string;
    };
    hangUpMessages: {
        title: (isActive: boolean) => string;
        success?: (isActive: boolean) => string;
        error: (err: string, isActive?: boolean) => string;
    };
};

// this componet was built to serve too different data sources
// so there might be some unneeded logic. once we have rollout the callstate
// you can feel free to thinki critically about some of the booleans and see if we can remove them
export const CallParticipantLayout = ({
    participantIcon,
    isContact,
    formattedName,
    participantName,
    participantTypePlaceholder,
    callStatus,
    participantConnected,
    formattedTime,
    shouldDisplayIndependentActionButtons,
    isParticipantOnHold,
    onHoldWrap,
    onHangUp,
    isHoldDisabled,
    isHangUpDisabled,
    holdMessages,
    hangUpMessages,
}: ParticipantLayoutProps) => {
    return (
        <div data-testid="call-participant" className={clsx(styles.contactInfo, styles.layoutContainer)}>
            <div className={clsx(styles.layoutContainer, styles.contactName)}>
                <Icon
                    icon={participantIcon}
                    variant={IconVariants.CIRCLE}
                    size={IconSizes.LARGE}
                    bgColor="purple-1"
                    color="purple-4"
                    className={isContact ? "" : styles.externalTransfer}
                />
                <div className={styles.infoContainer}>
                    {participantName && (
                        <div className={clsx(styles.name, "ellipsis")}>
                            <b>{formattedName}</b>
                        </div>
                    )}
                    <div className={styles.number}>{participantTypePlaceholder}</div>
                </div>
            </div>
            <div className={clsx(styles.status, "flex-column align-end")}>
                <div>
                    {callStatus}
                    {participantConnected && <span>&nbsp;|&nbsp;{formattedTime}</span>}
                </div>
                <div className={clsx(styles.buttons, "flex-row")}>
                    {shouldDisplayIndependentActionButtons && (
                        <>
                            <CallActionButton
                                triggered={isParticipantOnHold}
                                callBack={onHoldWrap}
                                icon="hold-call"
                                className={styles.actionButton}
                                togglerClassName={styles.toggler}
                                messages={holdMessages}
                                disabled={isHoldDisabled}
                            />
                            <CallActionButton
                                callBack={onHangUp}
                                icon="call-hang-up-cobra"
                                className={styles.hangUp}
                                messages={hangUpMessages}
                                disabled={isHangUpDisabled}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
