import { clsx } from "clsx";

import Icon, { IconSizes, IconVariants } from "Components/elements/Icon";
import CallActionButton from "Pages/agentDesktop/agentInterface/CallPanel/ActiveCall/CallActionButton/CallActionButton";

import styles from "./PendingParticipantLayout.module.scss";

export const PENDING_CANCEL_REASONS = {
    reason: "cancel_transfer",
    source: "agent_call_panel",
};

export const PENDING_MESSAGES = {
    hangUp: {
        title: () => "Cancel Transfer",
        error: (err: string) => `An error occured while canceling the call - ${err}`,
    },
};

type LayoutProps = {
    participantName?: string;
    isQueue: boolean;
    cancelTransfer: () => Promise<void>;
    messages: {
        hangUp: {
            title: (isActive: boolean) => string;
            error: (err: string) => string;
        };
    };
};
export const PendingParticipantLayout = ({ participantName, isQueue, cancelTransfer, messages }: LayoutProps) => {
    return (
        <div className={clsx(styles.contactInfo, styles.layoutContainer)} data-testid="pending-participant">
            <div className={clsx(styles.layoutContainer, styles.contactName)}>
                <Icon
                    icon={isQueue ? "queues" : "headset"}
                    variant={IconVariants.CIRCLE}
                    size={IconSizes.LARGE}
                    bgColor="purple-1"
                    color="purple-4"
                    className={styles.externalTransfer}
                />
                <div className={styles.infoContainer}>
                    <div className={clsx(styles.name, "ellipsis")}>
                        <b>{participantName}</b>
                    </div>
                    <div className={styles.number}>Internal Transfer</div>
                </div>
            </div>
            <div className={clsx(styles.status, "flex-column align-end")}>
                <div>Pending</div>

                <div className={clsx(styles.buttons, "flex-row")}>
                    <CallActionButton
                        callBack={cancelTransfer}
                        icon="call-hang-up-cobra"
                        className={styles.hangUp}
                        messages={messages.hangUp}
                    />
                </div>
            </div>
        </div>
    );
};
