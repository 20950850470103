import { useCallback, useMemo } from "react";

import { useSelector } from "react-redux";

import { holdParticipant } from "Services/ConversationsApiService";
import { useFlags } from "Services/FeatureFlagService";
import { getLogger } from "Services/LoggingService";
import { selectConference } from "Services/state/conferences";
import {
    selectSnapshotConferenceFriendlyID,
    selectSnapShotConferenceSid,
    selectConferenceSnapshotSingularActiveParticipant,
} from "Services/state/conferences/ConferenceSnapshotSelectors";
import { CallStatusEvent } from "Services/Task.service";

const ALLOWED_STATUSES = [CallStatusEvent.IN_PROGRESS, CallStatusEvent.RINGING];
const logger = getLogger("On hold hook");

export type UseHoldCallResult = { onHold: boolean; toggleHold: () => Promise<unknown> };

export const useLegacyHoldCall = (): UseHoldCallResult => {
    const { deviceCallSid, participants, conferenceSid, conferenceFriendlyId } = useSelector(selectConference);

    const participant = useMemo(() => {
        if (!participants) {
            return;
        }

        return Object.values(participants).find(
            ({ callSid, managerAction, status }) =>
                ALLOWED_STATUSES.includes(status as CallStatusEvent) && callSid !== deviceCallSid && !managerAction
        );
    }, [participants, deviceCallSid]);

    const toggleHold = useCallback(() => {
        if (!participant?.callSid) {
            const error = new Error("Call sid not found");
            logger.error(error);
            throw error;
        }

        return holdParticipant({
            conferenceSid,
            conferenceFriendlyId,
            callSid: participant.callSid,
            hold: !participant.onHold,
        });
    }, [participant, conferenceSid, conferenceFriendlyId]);

    const onHold = participant?.onHold || false;
    return { onHold, toggleHold };
};

export const useCallActionHold = (): UseHoldCallResult => {
    // flag
    const { rolloutUseConferenceStateSseActions } = useFlags();

    // legacy
    const { onHold: legacyOnHold, toggleHold: legacyToggleHold } = useLegacyHoldCall();

    // snapshot
    const snapshotConferenceFriendlyId = useSelector(selectSnapshotConferenceFriendlyID);
    const snapshotConferenceSid = useSelector(selectSnapShotConferenceSid);

    const participant = useSelector(selectConferenceSnapshotSingularActiveParticipant);
    // @ts-expect-error deal with this not being a queue
    const callSid = participant?.callSid;
    const snapshotOnHold = !!participant?.hold;

    const snapshotToggleHold = useCallback(() => {
        return holdParticipant({
            conferenceSid: snapshotConferenceSid,
            conferenceFriendlyId: snapshotConferenceFriendlyId,
            callSid: callSid,
            hold: !participant?.hold,
        });
    }, [callSid, participant?.hold, snapshotConferenceFriendlyId, snapshotConferenceSid]);

    const onHold = rolloutUseConferenceStateSseActions ? snapshotOnHold : legacyOnHold;
    const toggleHold = rolloutUseConferenceStateSseActions ? snapshotToggleHold : legacyToggleHold;

    return { onHold, toggleHold };
};
