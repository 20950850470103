import { formatPhoneNumber } from "Services/CommunicationService";

import type { RVTask, ConferenceParticipant } from "@regal-voice/shared-types";
import type { ParticipantInfo } from "Services/state/conferences";

export type UseCallTitleOptions = {
    participant?: ParticipantInfo | ConferenceParticipant<"contact">;
    task?: RVTask;
};

export const useCallTitle = ({ participant, task }: UseCallTitleOptions): string => {
    if (!participant || !task) {
        return "";
    }

    // @ts-expect-error right now we are a union of the two types
    // but we will clean up the rolloutStateSse flag and we can remove this
    const displayName = participant.name || formatPhoneNumber(participant.label);
    const taskTitle: string = task.attributes?.title || "";
    return taskTitle.match(/incoming/i) ? `${taskTitle} from ${displayName}` : `${taskTitle} to ${displayName}`;
};
