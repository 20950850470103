import { useEffect, useCallback, useMemo, useRef, useState } from "react";

import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { formatPhoneNumber } from "Services/CommunicationService";
import {
    selectSnapShotConferenceSid,
    selectSnapshotConferenceFriendlyID,
} from "Services/state/conferences/ConferenceSnapshotSelectors";
import { isPendingInboundConferenceTask } from "Services/Task.service";

import { useCallParticipantActions } from "./Hooks/useCallParticipantActions";
import { CallParticipantLayout, CALL_MESSAGES } from "./Layout/CallParticipantLayout";

import type { RVTask, ConferenceParticipant } from "@regal-voice/shared-types";
import type { NonQueueParticipant } from "Services/state/conferences/ConferenceSnapshotSlice";

type CallParticipantProps = {
    participants: Array<ConferenceParticipant>;
    participant: NonQueueParticipant;
    task: RVTask;
};

// time in milliseconds from conversation service
// can this be replaced with something or moved to time service?
export const formatTimeForParticipant = (timestamp: number) => {
    const now = new Date().getTime();
    const timeDiff = now - timestamp;
    const h = Math.floor(timeDiff / (3600 * 1000)); // Total hours
    const m = Math.floor((timeDiff % (3600 * 1000)) / (60 * 1000)); // Remaining minutes
    const s = Math.floor((timeDiff % (60 * 1000)) / 1000); // Remaining seconds
    const prepandZeroToTime = (value: number) => {
        return value < 10 ? `0${value}` : value;
    };
    return `${h ? `${prepandZeroToTime(h)}:` : ""}${m ? prepandZeroToTime(m) : "00"}:${
        s ? prepandZeroToTime(s) : "00"
    }`;
};

export const CallParticipant = ({ participant, participants, task }: CallParticipantProps) => {
    const interval = useRef(0);

    const [formattedTime, setFormattedTime] = useState<string>("00:00");
    const isContact = participant.type == "contact";
    const [callStatus, setCallStatus] = useState<string>();

    const isAgent = participant.type == "internal";
    const isExternalPhonebook = participant.type == "external-phonebook";
    const conferenceSid = useSelector(selectSnapShotConferenceSid);
    const conferenceFriendlyId = useSelector(selectSnapshotConferenceFriendlyID);
    const participantPhoneNumber =
        participant.type === "contact" || participant.type === "external" || participant.type === "external-phonebook"
            ? participant.phoneNumber
            : undefined;

    const {
        holdWrap,
        dropParticipantByCallSid,
        participantIconValue,
        participantTypePlaceholder,
        isHoldDisabled,
        isHangUpDisabled,
        participantConnected,
    } = useCallParticipantActions({
        // todo figure this out.
        // @ts-expect-error busy vs BUSY?
        status: participant.status,
        conferenceSid: conferenceSid,
        conferenceFriendlyId,
        callSid: participant.callSid,
        taskSid: participant.taskSid,
        participantPhoneNumber,
        managerAction: participant.managerAction,
        isContact,
        isAgent,
        isExternalPhonebook,
    });

    const getDefaultConnectionStatus = useCallback(() => {
        return isPendingInboundConferenceTask(task) ? "Ringing..." : "Connecting...";
    }, [task]);

    // in the future the label should be a formatted number and we can remove this
    const formattedName = formatPhoneNumber(participant.label);

    // this is set to true, because we only render this wehn we have 2 participants
    // keeping it for consistency and to keey the legacy code in place
    // but once we remove that we can remove.
    const shouldDisplayIndependentActionButtons = true;

    const statusMap = useMemo(() => {
        return participants
            ? Object.values(participants)
                  .map((p) => p.status)
                  .join(",")
            : "";
    }, [participants]);

    useEffect(() => {
        if (isEmpty(participants)) {
            setCallStatus(getDefaultConnectionStatus());
            return;
        }
        const participantsArray = Object.values(participants);
        if (participantsArray.length < 1) {
            setCallStatus("Connecting...");
        } else if (participant.status == "ringing") {
            setCallStatus("Ringing...");
        } else if (participant.status == "in-progress") {
            setCallStatus("Live call");
        } else {
            setCallStatus("Connecting...");
        }
        // moved this idea, better then doing the join in the useEffect
        // update when one of the participants status changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusMap]);

    // we have 1 internal per participant
    // if we eventaully have too many particpants and need a single interval
    // we can move to to the call bar or something and tick globally
    useEffect(() => {
        if (participantConnected && participant.joinedAt) {
            interval.current = window.setInterval(() => {
                if (participant.joinedAt) {
                    setFormattedTime(formatTimeForParticipant(participant.joinedAt));
                }
            }, 1000);
        } else {
            window.clearInterval(interval.current);
        }
        return () => {
            if (interval.current) {
                window.clearInterval(interval.current);
                interval.current = 0;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participantConnected, participant.joinedAt]);

    return (
        <CallParticipantLayout
            participantName={participant.label}
            isContact={isContact}
            formattedName={formattedName}
            participantIcon={participantIconValue}
            participantTypePlaceholder={participantTypePlaceholder}
            callStatus={callStatus}
            participantConnected={participantConnected}
            formattedTime={formattedTime}
            shouldDisplayIndependentActionButtons={shouldDisplayIndependentActionButtons}
            isParticipantOnHold={participant.hold}
            onHoldWrap={holdWrap}
            onHangUp={dropParticipantByCallSid}
            isHangUpDisabled={isHangUpDisabled}
            holdMessages={CALL_MESSAGES.hold}
            isHoldDisabled={isHoldDisabled}
            hangUpMessages={CALL_MESSAGES.hangUp}
        />
    );
};
