import { useCallback } from "react";

import { useSelector } from "react-redux";

import { useTwilioDevice } from "Hooks/useTwilioDevice/useTwilioDevice";
import { emitMuteEvent } from "Services/ConversationsApiService";
import { useFlags } from "Services/FeatureFlagService";
import {
    selectSnapshotConferenceFriendlyID,
    selectConferenceSnapshotSelf,
} from "Services/state/conferences/ConferenceSnapshotSelectors";
import { selectConference } from "Services/state/conferences/Selectors";

import { logger } from "../../CallBar.utils";

export type UseMuteCallResult = {
    isMuted: boolean;
    toggleMute: () => void;
};

export const useMuteLegacyCall = (): UseMuteCallResult => {
    const { activeCall } = useTwilioDevice();
    const { conferenceFriendlyId, deviceCallSid } = useSelector(selectConference);
    const toggleMute = useCallback(() => {
        if (!activeCall) {
            logger.error("Tried to mute call but no active call found");
            return;
        }

        const nextState = !activeCall.isMuted();
        activeCall.mute(nextState);
        if (!conferenceFriendlyId) {
            logger.error("Tried to mute call but no conference friendly id found");
            return;
        }

        emitMuteEvent({ conferenceFriendlyId, mute: nextState, callSid: deviceCallSid });
    }, [activeCall, conferenceFriendlyId, deviceCallSid]);

    const isMuted = activeCall?.isMuted() || false;
    return { isMuted, toggleMute };
};

export const useCallActionMute = (): UseMuteCallResult => {
    // there isn't a way to get rid of this right now
    const { activeCall } = useTwilioDevice();

    // flag
    const { rolloutUseConferenceStateSseActions } = useFlags();

    // legacy
    const { isMuted: isLegacyMuted, toggleMute: toggleLegacyMute } = useMuteLegacyCall();

    // snapshot
    const snapshotConferenceFriendlyId = useSelector(selectSnapshotConferenceFriendlyID);
    const agent = useSelector(selectConferenceSnapshotSelf);

    const { callSid: agentCallSid, muted: agentMuted } = agent || {};

    const toggleSnapshotMute = useCallback(() => {
        if (!activeCall) {
            logger.error("Tried to mute call but no active call found");
            return;
        }

        const toggledMuted = !agentMuted;
        activeCall.mute(toggledMuted);
        emitMuteEvent({
            conferenceFriendlyId: snapshotConferenceFriendlyId,
            mute: toggledMuted,
            callSid: agentCallSid,
        });
    }, [activeCall, agentMuted, snapshotConferenceFriendlyId, agentCallSid]);

    const isMuted = rolloutUseConferenceStateSseActions ? !!agentMuted : isLegacyMuted;
    const toggleMute = rolloutUseConferenceStateSseActions ? toggleSnapshotMute : toggleLegacyMute;

    return { isMuted, toggleMute };
};
