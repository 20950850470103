import { initSSE } from "Services/server-sent-events";

import { setBrandData } from "./BrandProvider";
import { selectBrand } from "./selectors";

import type { RootState, ThunkType, RVDispatch } from "../Storage";
import type { Brand } from "Types/Brand";

export function updateBrand(brand: Brand): ThunkType {
    return function thunk(dispatch: RVDispatch, getState: () => RootState): void {
        // getCurrentBrand graphql query returns different data than authenticateBrand()
        const mergedBrand = { ...selectBrand(getState()), ...brand };
        dispatch(setBrandData(mergedBrand));

        initSSE();
    };
}
