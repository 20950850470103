import { gql } from "@apollo/client";

import type { ExternalPhoneNumber } from "Types/ExternalPhoneNumber";

const returnFields: Array<keyof ExternalPhoneNumber> = [
    "id",
    "name",
    "phoneNumber",
    "createdBy",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
    "profileAttributesMap",
    "sipHeadersMap",
    "integration",
    "externalId",
    "sipUri",
];

// this is used to format the fields from returnFields with newline
// and indentation for better readability in the GraphQL mutation.
const returnVal = returnFields.join("\n    ");

export const createTransferPhoneNumber = gql`
    mutation CreateTransferPhoneNumber(
        $name: String!
        $phoneNumber: String!
        $teamIds: [String]
        $shouldLoadTeams: Boolean!
        $integration: String
        $profileAttributes: JSON
        $externalId: String
        $sipUri: String
        $profileAttributesMap: JSON
        $sipHeadersMap: JSON
    ) {
        createTransferPhoneNumber(
            createTransferPhoneNumberInput: {
                name: $name
                phoneNumber: $phoneNumber
                teamIds: $teamIds
                integration: $integration
                profileAttributes: $profileAttributes
                externalId: $externalId
                sipUri: $sipUri
                profileAttributesMap: $profileAttributesMap
                sipHeadersMap: $sipHeadersMap
            }
        ) {
            ${returnVal}
            teams @include(if: $shouldLoadTeams) {
                id
                name
            }
        }
    }
`;

export const updateTransferPhoneNumber = gql`
    mutation UpdateTransferPhoneNumber(
        $id: String!
        $name: String!
        $phoneNumber: String!
        $teamIds: [String]
        $shouldLoadTeams: Boolean!
        $integration: String
        $profileAttributes: JSON
        $externalId: String
        $sipUri: String
        $profileAttributesMap: JSON
        $sipHeadersMap: JSON
    ) {
        updateTransferPhoneNumber(
            updateTransferPhoneNumberInput: {
                id: $id
                name: $name
                phoneNumber: $phoneNumber
                teamIds: $teamIds
                integration: $integration
                profileAttributes: $profileAttributes
                externalId: $externalId
                sipUri: $sipUri
                profileAttributesMap: $profileAttributesMap
                sipHeadersMap: $sipHeadersMap
            }
        ) {
            ${returnVal}
            teams @include(if: $shouldLoadTeams) {
                id
                name
            }
        }
    }
`;

export const removeTransferPhoneNumber = gql`
    mutation RemoveTransferPhoneNumber($id: String!) {
        removeTransferPhoneNumber(id: $id) {
            id
        }
    }
`;
