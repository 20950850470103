import { useCallback } from "react";

import { useSelector } from "react-redux";

import { CallParticipant } from "Pages/agentDesktop/agentInterface/CallPanel/TaskInfo/CallParticipant/CallParticipant";
import { LegacyCallParticipant } from "Pages/agentDesktop/agentInterface/CallPanel/TaskInfo/CallParticipant/LegacyCallParticipant/LegacyCallParticipant";
import { LegacyPendingParticipant } from "Pages/agentDesktop/agentInterface/CallPanel/TaskInfo/PendingParticipant/LegacyPendingParticipant/LegacyPendingParticipant";
import { PendingParticipant } from "Pages/agentDesktop/agentInterface/CallPanel/TaskInfo/PendingParticipant/PendingParticipant";
import {
    TransfersPanel,
    type TransferPanelHeaderRenderer,
} from "Pages/agentDesktop/agentInterface/CallPanel/TransfersPanel/TransfersPanel";
import { useFlags } from "Services/FeatureFlagService";
import { selectWorkerUri } from "Services/state/agent/AgentInformationSlice";
import { ParticipantInfo } from "Services/state/conferences";

import { useAgents } from "../../hooks";
import { useConferenceParticipants } from "../../hooks/useConferenceParticipants/useConferenceParticipants";
import { TransfersHeader } from "./TransfersHeader";

import type { ConferenceParticipant, RVTask } from "@regal-voice/shared-types";
import type { Agent } from "Types/Agent";

import styles from "./TransfersWithParticipants.module.scss";

export type TransfersWithParticipantsProps = {
    activeConferenceTask?: RVTask;
    onCloseRequest: () => void;
};

export function TransfersWithParticipants({
    activeConferenceTask,
    onCloseRequest,
}: TransfersWithParticipantsProps): JSX.Element {
    const { rolloutUseConferenceStateSse } = useFlags();

    const workerUri = useSelector(selectWorkerUri);
    const { activeParticipants } = useConferenceParticipants({
        task: activeConferenceTask,
    });
    const { agents } = useAgents();

    const renderPanelHeader = useCallback<TransferPanelHeaderRenderer>(
        (headerProps) => <TransfersHeader {...headerProps} />,
        []
    );

    if (!workerUri || !activeConferenceTask) {
        return <></>;
    }

    return (
        <div className={styles.container}>
            <TransfersPanel
                currentWorkerUri={workerUri}
                conferenceFriendlyId={activeConferenceTask?.attributes.conferenceFriendlyId}
                regalVoicePhone={activeConferenceTask?.attributes.regalVoicePhone}
                onCloseRequest={onCloseRequest}
                taskSid={activeConferenceTask?.taskSid}
                closeOnTransfer={false}
                asPopover
                renderHeader={renderPanelHeader}
            />

            {rolloutUseConferenceStateSse ? (
                <ParticipantTransfer
                    participants={activeParticipants as unknown as Array<ConferenceParticipant>}
                    activeConferenceTask={activeConferenceTask}
                />
            ) : (
                <LegacyTransferDisplay
                    workerUri={workerUri}
                    participants={activeParticipants as Array<ParticipantInfo>}
                    activeConferenceTask={activeConferenceTask}
                    agents={agents}
                />
            )}
        </div>
    );
}

const LegacyTransferDisplay = ({
    workerUri,
    participants,
    activeConferenceTask,
    agents,
}: {
    workerUri?: string;
    participants: Array<ParticipantInfo>;
    activeConferenceTask: RVTask;
    agents: Agent[];
}) => {
    return (
        workerUri &&
        participants.length > 1 && (
            <div className={styles.participants}>
                {participants.map((participant) => {
                    if (participant.status) {
                        return (
                            <LegacyCallParticipant
                                key={participant.callSid}
                                participant={participant}
                                task={activeConferenceTask}
                                currentWorkerUri={workerUri}
                                agents={agents}
                            />
                        );
                    }

                    return (
                        <LegacyPendingParticipant key={participant.callSid} agents={agents} participant={participant} />
                    );
                })}
            </div>
        )
    );
};

const checkIsCallPending = (participant: ConferenceParticipant): boolean => {
    return participant.type == "queue" || (participant.status === "ringing" && participant.type === "internal");
};

type NonQueueParticipant = Exclude<ConferenceParticipant, { type: "queue" }>;

export const ParticipantTransfer = ({
    participants,
    activeConferenceTask,
}: {
    participants: Array<ConferenceParticipant>;
    activeConferenceTask: RVTask;
}) => {
    const hasParticipants = participants.length > 1;
    return (
        (hasParticipants && (
            <div className={styles.participants}>
                {participants.map((participant) => {
                    const isCallPending = checkIsCallPending(participant);
                    if (isCallPending) {
                        return (
                            <PendingParticipant
                                key={participant.label}
                                taskSid={participant.taskSid}
                                participantName={participant.label}
                                isQueue={participant.type === "queue"}
                            />
                        );
                    }
                    return (
                        <CallParticipant
                            key={participant.label}
                            participant={participant as NonQueueParticipant}
                            participants={participants}
                            task={activeConferenceTask}
                        />
                    );
                })}
            </div>
        )) || <></>
    );
};
