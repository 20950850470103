import { useCallback, useMemo } from "react";

import { useSelector } from "react-redux";

import { useTwilioDevice } from "Hooks/useTwilioDevice/useTwilioDevice";
import { useFlags } from "Services/FeatureFlagService";
import { selectBargingParticipants } from "Services/state/conferences";
import { selectConferenceSnapshotBarges } from "Services/state/conferences/ConferenceSnapshotSelectors";
import { clearConferenceSnapshot } from "Services/state/conferences/ConferenceSnapshotSlice";
import { useRVDispatch } from "Services/state/Storage";
import { setActiveTask } from "Services/state/tasks/Thunks";

import { BUTTONS_MESSAGES, type CallBarButtonMessagesFactory } from "../../CallBar.consts";
import { logger } from "../../CallBar.utils";
import { useAgents } from "../useAgents";

import type { RVTask } from "@regal-voice/shared-types";
import type { Agent } from "Types/Agent";

export type UseHangUpCallOptions = {
    activeConferenceTask?: RVTask;
};

type HangUpCallFn = (alreadyHangingUp?: boolean) => void;

export type UseHangUpCallResult = {
    hangUpCall: HangUpCallFn;
    hangUpButtonMessages: CallBarButtonMessagesFactory;
};

const useHangUpCallData = ({ agents }: { agents: Array<Agent> }) => {
    const { rolloutUseConferenceStateSseActions } = useFlags();

    const legacyBarges = useSelector(selectBargingParticipants);
    const snapshotBarges = useSelector(selectConferenceSnapshotBarges);

    const bargingParticipantNames = useMemo(() => {
        if (rolloutUseConferenceStateSseActions) {
            return snapshotBarges.map(({ label }) => {
                return label;
            });
        } else {
            const mappedBarges = legacyBarges.map(({ phoneNumber }) => {
                const foundAgent =
                    agents.find((a) => {
                        return a.twilioContactUri == phoneNumber;
                    })?.name || "A manager";
                return foundAgent;
            });
            return mappedBarges;
        }
    }, [legacyBarges, rolloutUseConferenceStateSseActions, snapshotBarges, agents]);

    return { bargingParticipantNames };
};

export const useCallActionHangup = ({ activeConferenceTask }: UseHangUpCallOptions): UseHangUpCallResult => {
    const { agents } = useAgents();
    const dispatch = useRVDispatch();
    const { bargingParticipantNames } = useHangUpCallData({ agents });
    const { device } = useTwilioDevice();
    const { rolloutUseConferenceStateSse, rolloutUseConferenceStateSseActions } = useFlags();

    const hangUpCall = useCallback<HangUpCallFn>(
        (alreadyHangingUp) => {
            logger.log("Attempting to hang up", { device, param: alreadyHangingUp });
            if (activeConferenceTask) {
                if (!rolloutUseConferenceStateSseActions) {
                    dispatch(setActiveTask(activeConferenceTask));
                }
            }
            // we dont get a state update when we hang up. So we need to clear the conference state manually
            if (rolloutUseConferenceStateSse) {
                dispatch(clearConferenceSnapshot());
            }
            device?.disconnectAll();
        },
        [device, activeConferenceTask, rolloutUseConferenceStateSse, rolloutUseConferenceStateSseActions, dispatch]
    );
    const hangUpButtonMessages = useMemo(() => {
        if (bargingParticipantNames.length == 0) {
            return BUTTONS_MESSAGES.hangUp;
        }

        let title: string;

        if (bargingParticipantNames.length == 1) {
            const [firstParticipant] = bargingParticipantNames;
            title = `${firstParticipant} is barging. Ending this call will end it for all parties.`;
        } else {
            const [firstParticipant, ...otherParticipants] = bargingParticipantNames;
            const otherParticipantsAreMoreThanOne = otherParticipants.length > 1;
            const label = `${firstParticipant} + ${otherParticipants.length} other${
                otherParticipantsAreMoreThanOne ? "s" : ""
            }`;
            title = `${label} are barging. Ending this call will end it for all parties.`;
        }

        return {
            ...BUTTONS_MESSAGES.hangUp,
            title: () => title,
        };
    }, [bargingParticipantNames]);

    return { hangUpCall, hangUpButtonMessages };
};
