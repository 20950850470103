import { useCallback } from "react";

import { useMutation } from "@apollo/client";

import { cancelTasks } from "Services/marketing-api/tasks/mutations";

import { PendingParticipantLayout, PENDING_CANCEL_REASONS, PENDING_MESSAGES } from "./Layout/PendingParticipantLayout";

type PendingProps = {
    taskSid: string;
    participantName: string;
    isQueue: boolean;
};
export const PendingParticipant = ({ taskSid, participantName, isQueue }: PendingProps) => {
    const [cancelTaskList] = useMutation<{ cancelTasks: string[] }>(cancelTasks);
    const cancelTransfer = useCallback(async () => {
        await cancelTaskList({
            variables: {
                ids: [taskSid],
                cancelAttributes: PENDING_CANCEL_REASONS,
            },
        });
    }, [cancelTaskList, taskSid]);
    return (
        <PendingParticipantLayout
            participantName={participantName}
            isQueue={isQueue}
            cancelTransfer={cancelTransfer}
            messages={PENDING_MESSAGES}
        />
    );
};
